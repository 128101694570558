import React from 'react';

import mapa from '../styles/mapa.module.scss'
/*eslint-disable*/
export default function() {
  return (
    <div className={mapa.raiz}>
      <h3>Dirección</h3>
      <p>Av. Vía 2, No. 104, piso 2, Local 203 Tabasco 2000, CP 86035. Villahermosa, Tabasco.</p>
      <iframe frameBorder="0" style={{border: 0}}
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJTct3fovX7YURJmaEblGCVtM&key=AIzaSyD_6Nkvy5H2OS6izkqut_ck-NIvuznIsZs"
              allowFullScreen></iframe>
    </div>
  )
}