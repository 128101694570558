import React from "react"

import Layout from '../components/layout'
import Contacto from  '../components/contacto'

import css from '../styles/index.module.scss'


import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


//Imagenes importadas
import mapac from "../assets/mapa colores.jpg"
import weatherford from "../assets/clientes/weatherford.png"
import schlumberger from "../assets/clientes/schlumberger.png"
import typhoon from "../assets/clientes/typhoon.png"
import geolis from "../assets/clientes/geolis.jpg"
import petricore from "../assets/clientes/petricore.png"
import pemex from "../assets/clientes/pemex.gif"
import lifting from "../assets/clientes/lifting.png"
import sgs from "../assets/clientes/sgs.png"
import apollo from "../assets/clientes/apollo.jpg"
import olimpia from "../assets/clientes/olimpia.png"
import fondoweb from '../assets/FondoWeb1.png'
import sismica1 from '../assets/sismica1.jpg'
import sismica2 from '../assets/sismica2.jpg'
import petrofisica from '../assets/petroweb.png'
import modelo1 from '../assets/modelo1.png'
import modelo2 from '../assets/modelo2.png'

const IndexPage = () => {

  return (
    <Layout className={css.index}>
      <Carousel autoPlay infiniteLoop transitionTime={1000} showStatus={false} showIndicators={false} showThumbs={false}
                swipeable dynamicHeight={true}>
        <div>
          <img src={fondoweb} style={{  overflowY: 'hidden' }} className={css.itemcarousel} alt={""}/>
        </div>
        <div>
          <div className={`${css.itemcarousel} ${css.hoja2}`}>
            <img src={sismica1} alt={""}/>
            <img src={sismica2} alt={""}/>
            <img src={petrofisica} alt={""}/>
          </div>
        </div>
        <div>
          <div className={`${css.itemcarousel} ${css.hoja3}`}>
            <img src={modelo1} alt={""}/>
            <img src={modelo2} alt={""}/>
          </div>
        </div>
      </Carousel>
      <section className={css.quienes}>
        <div className={css.quienestxt}>
          <h1>INNOVA Management & Development Consulting.</h1>
          <p>Empresa Mexicana, que busca la excelencia en cada compromiso adquirido. La consultora fundada en 2016
            conjuga más de tres décadas de experiencia, aplicadas a las principales cuencas geológicas de México. Ha
            participado en el diseño y ejecución de la explotación y exploración de pozos en tierra y costa afuera,
            relacionados a yacimientos clásticos y carbonáticos.</p>
        </div>
        <div className={css.quienesimg}>
          <img className={css.mapac} src={mapac} alt={""}/>
        </div>
      </section>
      {/*Nuestros Clientes*/}
      <section className={css.clientesraiz}>
        <div className={css.bloque}>
          <h2>CLIENTES</h2>
        </div>
        <div className={css.bloqueaux}/>
      </section>
      {/*Galería de Clientes*/}
      <section className={css.clientesgrid}>
        <LogoEmpresa src={weatherford}/>
        <LogoEmpresa src={schlumberger}/>
        <LogoEmpresa src={typhoon}/>
        <LogoEmpresa src={geolis}/>
        <LogoEmpresa src={petricore}/>
        <LogoEmpresa src={pemex}/>
        <LogoEmpresa src={lifting}/>
        <LogoEmpresa src={sgs}/>
        <LogoEmpresa src={apollo}/>
        <LogoEmpresa src={olimpia}/>
      </section>
      <Contacto/>
    </Layout>
  )
}

const LogoEmpresa = ( props ) => (
  <span className={css.logoraiz}>
    <img className={css.logoempresa} src={props.src} alt={""}/>
  </span>
)

export default IndexPage
