import React from "react"
import useForm from 'react-hook-form';

import css from '../styles/contacto.module.scss'
import form from '../styles/fcontacto.module.scss'
import Mapa from './mapa';

function Form() {
  //eslint-disable-next-line
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    var nombre = data['Nombre Completo'];
    var compañia = data['Compañía'];
    var mensaje = data['Mensaje'];

    if(nombre == undefined || compañia == undefined || mensaje == undefined) {
      alert('Para enviar un mensaje, por favor complete el formulario.');
      return;
    }
    window.open(`mailto:nel.salinas@innova-md.com?subject=${nombre} | ${compañia}&body=${mensaje}`, '_blank');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={form.raiz}>
      <label htmlFor="Nombre Completo">Nombre Completo*</label>
      <input type="text" name="Nombre Completo" ref={register({required: true, maxLength: 80})} />
      <label htmlFor="Compañía">Compañía*</label>
      <input type="text" name="Compañía" ref={register({required: true, maxLength: 80})} />
      <label htmlFor="Email">Correo Electrónico*</label>
      <input type="text" name="Email" ref={register({required: true, pattern: /^\S+@\S+$/i})} />
      <textarea name="Mensaje" ref={register({required: true, minLength: 10, maxLength: 500})} />
      <input type="submit" value="Enviar"/>
    </form>
  );
}

const Contacto = () => {

  return (
    <div id={"contactar"} className={css.raiz}>
      <Form/>
      <Mapa/>
    </div>
  );
}

export default Contacto